import http from '../http-common'
import { APIBaseFunctions, APIDataSliceObj, defaultAPILimit } from '@/components/Utility/APIBase'

class ExternalServicesDataService extends APIBaseFunctions {
  private cachedExternalServicesDataSliceObj: APIDataSliceObj = {
    slicemode: 0,
    start: 0,
    limit: defaultAPILimit,
    page: 0,
    pagesize: 0,
    totalcount: 0
  }

  getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    const currentDataSliceObj = ExternalServicesDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedExternalServicesDataSliceObj }, dataSliceObj)

    if (sortingOrder.length > 0) {
      return http.get(`/ekstern-services?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    return http.get(`/ekstern-services?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
  }

  get (id: string) {
    return http.get(`/ekstern-services/${id}`)
  }

  getCount () {
    return http.get('/ekstern-services/count')
  }

  create (data: any) {
    return http.post('/ekstern-services', data)
  }

  update (id: string, data: any) {
    return http.put(`/ekstern-services/${id}`, data)
  }

  delete (id: string) {
    return http.delete(`/ekstern-services/${id}`)
  }

  deleteAll () {
    return http.delete('/ekstern-services')
  }
}

export default new ExternalServicesDataService()
